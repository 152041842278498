var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "28",
        height: "15",
        viewBox: "0 0 28 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.55319 0.319142C2.55319 0.145061 2.40813 0 2.23405 0H0.319142C0.145061 0 0 0.145061 0 0.319142V14.6809C0 14.8549 0.145061 15 0.319142 15H2.23405C2.40813 15 2.55319 14.8549 2.55319 14.6809V11.8666H24.9807V14.6809C24.9807 14.8549 25.1258 15 25.2998 15H27.2147C27.3888 15 27.5339 14.8549 27.5339 14.6809V9.31335H2.52418V0.319142H2.55319Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.61564 8.21174C8.25006 8.21174 9.57502 6.88678 9.57502 5.25236C9.57502 3.61793 8.25006 2.29297 6.61564 2.29297C4.98121 2.29297 3.65625 3.61793 3.65625 5.25236C3.65625 6.88678 4.98121 8.21174 6.61564 8.21174Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.8377 4.67058L12.6811 2.05936C11.6366 1.82726 10.6211 2.63964 10.6211 3.71314V8.21024H27.536V8.03615C27.565 6.41139 26.4335 4.98973 24.8377 4.67058Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }