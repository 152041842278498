var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "17",
        height: "17",
        viewBox: "0 0 17 17",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.8205 0L11.8513 1.03076C11.4247 1.45729 11.4247 2.14785 11.8513 2.57437C12.2778 3.0009 12.9683 3.0009 13.3949 2.57437L14.4256 3.60514C13.9991 4.03166 13.9991 4.7273 14.4256 5.15382C14.8522 5.58035 15.5427 5.58035 15.9743 5.15382L17 6.18459L14.9232 8.26135L8.74373 2.07676L10.8205 0V0ZM14.3038 8.88082L6.18459 17L5.15382 15.9743C5.58035 15.5427 5.58035 14.8522 5.15382 14.4256C4.7273 13.9991 4.03166 13.9991 3.60514 14.4256L2.57437 13.3949C3.0009 12.9683 3.0009 12.2778 2.57437 11.8513C2.14785 11.4247 1.45729 11.4247 1.03076 11.8513L0 10.8205L8.12425 2.69624L14.3038 8.88082Z",
          fill: "#0071BC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }