var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkoutGiftCard" }, [
    _c("div", { staticClass: "sidebarRow giftCardRowTitle" }, [
      _c(
        "h5",
        { staticClass: "sidebarStepTitle" },
        [
          _c(
            "b-form-checkbox",
            {
              on: { change: _vm.toggleGift },
              model: {
                value: _vm.isGift,
                callback: function ($$v) {
                  _vm.isGift = $$v
                },
                expression: "isGift",
              },
            },
            [
              _c("span", { staticClass: "labelGiftCard ml-1" }, [
                _vm._v("È un regalo?"),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", [
      _c(
        "h5",
        { staticClass: "sidebarAccordionTitle giftCardModalLink small" },
        [
          _vm._v(
            "\n            Puoi inviare il voucher al destinatario via email oppure puoi scegliere la scatola con dentro una gift card da collezione.\n            "
          ),
          _c(
            "strong",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modalGiftCardsInfo",
                  modifiers: { modalGiftCardsInfo: true },
                },
              ],
            },
            [_vm._v("Vedi la gift card!")]
          ),
        ]
      ),
    ]),
    _vm.isGift
      ? _c(
          "div",
          [
            _c("ModalGiftCard", { attrs: { id: "modalGiftCard_" + _vm._uid } }),
            _vm._m(0),
            _c("br"),
            _c("div", { staticClass: "sidebarRow giftCardRow" }, [
              _c(
                "div",
                { staticClass: "customInputBlock" },
                [
                  _c("b-form-input", {
                    ref: "giftCardFirstName",
                    attrs: {
                      size: _vm.inputSize,
                      type: "text",
                      placeholder: "Nome destinatario *",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validateBlock()
                      },
                    },
                    model: {
                      value: _vm.giftCardFirstName,
                      callback: function ($$v) {
                        _vm.giftCardFirstName = $$v
                      },
                      expression: "giftCardFirstName",
                    },
                  }),
                  _c("span", { staticClass: "formError" }),
                  _c("span", {
                    staticClass: "formClear",
                    on: {
                      click: function ($event) {
                        return _vm.clearField("giftCardFirstName")
                      },
                    },
                  }),
                  _c("SvgInfoCircle", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: "Nome del destinatario del regalo",
                        expression: "'Nome del destinatario del regalo'",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass: "svgInfo cursor-pointer",
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "sidebarRow giftCardRow" }, [
              _c(
                "div",
                { staticClass: "customInputBlock" },
                [
                  _c("b-form-input", {
                    ref: "giftCardLastName",
                    attrs: {
                      size: _vm.inputSize,
                      type: "text",
                      placeholder: "Cognome destinatario *",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validateBlock()
                      },
                    },
                    model: {
                      value: _vm.giftCardLastName,
                      callback: function ($$v) {
                        _vm.giftCardLastName = $$v
                      },
                      expression: "giftCardLastName",
                    },
                  }),
                  _c("span", { staticClass: "formError" }),
                  _c("span", {
                    staticClass: "formClear",
                    on: {
                      click: function ($event) {
                        return _vm.clearField("giftCardLastName")
                      },
                    },
                  }),
                  _c("SvgInfoCircle", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: "Cognome del destinatario del regalo",
                        expression: "'Cognome del destinatario del regalo'",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass: "svgInfo cursor-pointer",
                  }),
                ],
                1
              ),
            ]),
            _c(
              "h5",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.giftCardEmail",
                    modifiers: { giftCardEmail: true },
                  },
                ],
                class:
                  "sidebarAccordionTitle " +
                  (_vm.giftCardEmailVisible ? "activeStep" : ""),
              },
              [
                _c("span", { staticClass: "labelGiftCard" }, [
                  _vm._v("Lo voglio regalare via email"),
                ]),
                _c("SvgChevronDown"),
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: { id: "giftCardEmail", accordion: "giftCardStatus" },
                model: {
                  value: _vm.giftCardEmailVisible,
                  callback: function ($$v) {
                    _vm.giftCardEmailVisible = $$v
                  },
                  expression: "giftCardEmailVisible",
                },
              },
              [
                _c("div", { staticClass: "sidebarRow giftCardRow mb-3" }, [
                  _c(
                    "h5",
                    { staticClass: "sidebarStepTitle text-left" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          on: { change: _vm.toggleShippingData },
                          model: {
                            value: _vm.useBillingData,
                            callback: function ($$v) {
                              _vm.useBillingData = $$v
                            },
                            expression: "useBillingData",
                          },
                        },
                        [
                          _c("p", { staticClass: "small mb-0" }, [
                            _vm._v(
                              "\n                           Vuoi consegnare il voucher di persona? Usa lo stesso indirizzo email usato sopra.\n                           "
                            ),
                            _c("br"),
                            _c(
                              "strong",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.modalGiftCardsInfo",
                                    modifiers: { modalGiftCardsInfo: true },
                                  },
                                ],
                                staticClass: "underline",
                              },
                              [_vm._v("Vedi la gift card!")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardEmail",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "email",
                          placeholder: "Email *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardEmail,
                          callback: function ($$v) {
                            _vm.giftCardEmail = $$v
                          },
                          expression: "giftCardEmail",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardEmail")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Scrivi qui l'email del destinatario",
                            expression:
                              "'Scrivi qui l\\'email del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardEmailConfirm",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "email",
                          placeholder: "Conferma email *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardEmailConfirm,
                          callback: function ($$v) {
                            _vm.giftCardEmailConfirm = $$v
                          },
                          expression: "giftCardEmailConfirm",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardEmailConfirm")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Conferma l'email del destinatario",
                            expression: "'Conferma l\\'email del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-textarea", {
                        ref: "giftCardMessage",
                        attrs: {
                          size: _vm.inputSize,
                          placeholder: "Messaggio per il destinatario",
                          "no-resize": "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardMessage,
                          callback: function ($$v) {
                            _vm.giftCardMessage = $$v
                          },
                          expression: "giftCardMessage",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardMessage")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value:
                              "Scrivi qui il messaggio per il destinatario",
                            expression:
                              "'Scrivi qui il messaggio per il destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "h5",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.giftCardBox",
                    modifiers: { giftCardBox: true },
                  },
                ],
                class:
                  "sidebarAccordionTitle " +
                  (_vm.giftCardBoxVisible ? "activeStep" : ""),
              },
              [
                _c("span", { staticClass: "labelGiftCard" }, [
                  _vm._v("Voglio regalare scatola e gift card"),
                ]),
                _c("SvgChevronDown"),
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: { id: "giftCardBox", accordion: "giftCardStatus" },
                model: {
                  value: _vm.giftCardBoxVisible,
                  callback: function ($$v) {
                    _vm.giftCardBoxVisible = $$v
                  },
                  expression: "giftCardBoxVisible",
                },
              },
              [
                _c("div", { staticClass: "sidebarRow giftCardRow mb-3" }, [
                  _c(
                    "h5",
                    { staticClass: "sidebarStepTitle text-left" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          on: { change: _vm.toggleShippingData },
                          model: {
                            value: _vm.useBillingData,
                            callback: function ($$v) {
                              _vm.useBillingData = $$v
                            },
                            expression: "useBillingData",
                          },
                        },
                        [
                          _c("p", { staticClass: "small mb-0" }, [
                            _vm._v(
                              "\n                            Vuoi consegnare di persona il regalo? Usa lo stesso indirizzo che hai inserito sopra.\n                            "
                            ),
                            _c("br"),
                            _c(
                              "strong",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.modalGiftCardsInfo",
                                    modifiers: { modalGiftCardsInfo: true },
                                  },
                                ],
                                staticClass: "underline",
                              },
                              [_vm._v("Vedi la gift card!")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("p", { staticClass: "text-center" }, [
                  _c("small", [_vm._v("Indirizzo di consegna")]),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardShippingFirstName",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "text",
                          placeholder: "Nome destinatario *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardShippingFirstName,
                          callback: function ($$v) {
                            _vm.giftCardShippingFirstName = $$v
                          },
                          expression: "giftCardShippingFirstName",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardShippingFirstName")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Nome del destinatario della spedizione",
                            expression:
                              "'Nome del destinatario della spedizione'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardShippingLastName",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "text",
                          placeholder: "Cognome destinatario *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardShippingLastName,
                          callback: function ($$v) {
                            _vm.giftCardShippingLastName = $$v
                          },
                          expression: "giftCardShippingLastName",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardShippingLastName")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Cognome del destinatario della spedizione",
                            expression:
                              "'Cognome del destinatario della spedizione'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardAddress",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "text",
                          placeholder: "Indirizzo *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardAddress,
                          callback: function ($$v) {
                            _vm.giftCardAddress = $$v
                          },
                          expression: "giftCardAddress",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardAddress")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Indirizzo del destinatario",
                            expression: "'Indirizzo del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardCo",
                        attrs: {
                          size: _vm.inputSize,
                          type: "text",
                          placeholder:
                            "Dettagli indirizzo (azienda, scala, ecc.)",
                        },
                        model: {
                          value: _vm.giftCardCo,
                          callback: function ($$v) {
                            _vm.giftCardCo = $$v
                          },
                          expression: "giftCardCo",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardCo")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Dettagli indirizzo (azienda, scala, ecc.)",
                            expression:
                              "'Dettagli indirizzo (azienda, scala, ecc.)'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardPostalCode",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "text",
                          placeholder: "CAP *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardPostalCode,
                          callback: function ($$v) {
                            _vm.giftCardPostalCode = $$v
                          },
                          expression: "giftCardPostalCode",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardPostalCode")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "CAP del destinatario",
                            expression: "'CAP del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardTown",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "text",
                          placeholder: "Città *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardTown,
                          callback: function ($$v) {
                            _vm.giftCardTown = $$v
                          },
                          expression: "giftCardTown",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardTown")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Città del destinatario",
                            expression: "'Città del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardProvince",
                        attrs: {
                          size: _vm.inputSize,
                          disabled: _vm.useBillingData,
                          type: "text",
                          placeholder: "Provincia *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardProvince,
                          callback: function ($$v) {
                            _vm.giftCardProvince = $$v
                          },
                          expression: "giftCardProvince",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardProvince")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Provincia del destinatario",
                            expression: "'Provincia del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardCountry",
                        attrs: {
                          disabled: "",
                          size: _vm.inputSize,
                          type: "text",
                          placeholder: "Paese *",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardCountry,
                          callback: function ($$v) {
                            _vm.giftCardCountry = $$v
                          },
                          expression: "giftCardCountry",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardCountry")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value:
                              "Questo servizio è disponibile solo verso l' Italia",
                            expression:
                              "'Questo servizio è disponibile solo verso l\\' Italia'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "customInputBlock" },
                  [
                    _c("b-form-input", {
                      ref: "giftCardPhone",
                      attrs: {
                        size: _vm.inputSize,
                        disabled: _vm.useBillingData,
                        type: "text",
                        placeholder: "Cellulare del destinatario *",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.validateBlock()
                        },
                      },
                      model: {
                        value: _vm.giftCardPhone,
                        callback: function ($$v) {
                          _vm.giftCardPhone = $$v
                        },
                        expression: "giftCardPhone",
                      },
                    }),
                    _c("span", { staticClass: "formError" }),
                    _c("span", {
                      staticClass: "formClear",
                      on: {
                        click: function ($event) {
                          return _vm.clearField("giftCardPhone")
                        },
                      },
                    }),
                    _c("SvgInfoCircle", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top",
                          value: "Cellulare del destinatario, per il corriere",
                          expression:
                            "'Cellulare del destinatario, per il corriere'",
                          modifiers: { hover: true, top: true },
                        },
                      ],
                      staticClass: "svgInfo cursor-pointer",
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "sidebarRow giftCardRow" }, [
                  _c(
                    "div",
                    { staticClass: "customInputBlock mb-2" },
                    [
                      _c("b-form-input", {
                        ref: "giftCardBoxEmail",
                        attrs: {
                          size: _vm.inputSize,
                          type: "email",
                          placeholder: "Email del destinatario",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.validateBlock()
                          },
                        },
                        model: {
                          value: _vm.giftCardBoxEmail,
                          callback: function ($$v) {
                            _vm.giftCardBoxEmail = $$v
                          },
                          expression: "giftCardBoxEmail",
                        },
                      }),
                      _c("span", { staticClass: "formError" }),
                      _c("span", {
                        staticClass: "formClear",
                        on: {
                          click: function ($event) {
                            return _vm.clearField("giftCardBoxEmail")
                          },
                        },
                      }),
                      _c("SvgInfoCircle", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value: "Scrivi qui l'email del destinatario",
                            expression:
                              "'Scrivi qui l\\'email del destinatario'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "svgInfo cursor-pointer",
                      }),
                    ],
                    1
                  ),
                ]),
                _c("p", { staticClass: "small text-center emailWarning" }, [
                  _vm._v(
                    "\n              Se ci fornisci l'email del destinatario gli invieremo una notifica della spedizione (opzionale)\n            "
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel panel-white" }, [
      _c("span", { staticClass: "small" }, [
        _vm._v("Per acquisti multipli con più di un destinatario scrivici a: "),
        _c("a", { attrs: { href: "mailto:sales@salabam.com" } }, [
          _vm._v("sales@salabam.com"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }