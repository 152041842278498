<template>
    <div class="checkoutPax">
        <div class="sidebarRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestFirstName" ref="guestFirstName" type="text" :placeholder="strings[subject].guestFirstName_input" data-e2e="guestFirstName"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestFirstName')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="strings[subject].guestFirstName_tooltip"/>
            </div>
        </div>
        <div class="sidebarRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestLastName" ref="guestLastName" type="text" :placeholder="strings[subject].guestLastName_input" data-e2e="guestLastName"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestLastName')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="strings[subject].guestLastName_tooltip"/>
            </div>
        </div>
        <div class="sidebarRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestEmail" ref="guestEmail" type="email" :placeholder="strings[subject].guestEmail_input" data-e2e="guestEmail"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestEmail')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="strings[subject].guestEmail_tooltip" />
            </div>
        </div>
        <div class="sidebarRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestEmailConf" ref="guestEmailConf" type="email" :placeholder="strings[subject].guestEmailConf_input" data-e2e="guestEmailConf" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestEmailConf')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="strings[subject].guestEmailConf_tooltip" />
            </div>
        </div>
        <div class="sidebarRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestPhone" ref="guestPhone" type="tel" :placeholder="strings[subject].guestPhone_input" data-e2e="guestPhone"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestPhone')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="strings[subject].guestPhone_tooltip" />
            </div>
        </div>
    </div>
</template>

<script>
import SvgInfoCircle from './../../svg/info-circle'

export default {
    name: 'checkoutPax',
    components:
    {
        SvgInfoCircle,
    },
    props:
    {
        inputSize: String
    },
    mounted()
    {
        this.validateBlock()
    },
    data()
    {
        return {
            guestFirstName: (process.env.VUE_APP_MODE == 'development')? 'Pinco' : '',
            guestLastName: (process.env.VUE_APP_MODE == 'development')? 'Pallo' : '',
            guestEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestEmailConf: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestPhone: (process.env.VUE_APP_MODE == 'development')? '3934288350' : '',

            subject: (this.mixinGetIntegrationInfo().mode == 'booking' ? 'viaggiatore' : 'beneficiario'),
            strings: {

              'viaggiatore': {

                'guestFirstName_input': 'Nome del viaggiatore principale *',
                'guestFirstName_tooltip': 'Inserisci il nome del viaggiatore di riferimento, che sarà presente al checkin',

                'guestLastName_input': 'Cognome del viaggiatore principale *',
                'guestLastName_tooltip': 'Inserisci il cognome del viaggiatore di riferimento, che sarà presente al checkin',

                'guestEmail_input': 'Email del viaggiatore *',
                'guestEmail_tooltip': 'La conferma di prenotazione con il voucher per fare checkin in hotel verrà inviato a questo indirizzo',

                'guestEmailConf_input': 'Conferma email del viaggiatore *',
                'guestEmailConf_tooltip': 'Te la facciamo riscrivere per scoprire eventuali errori di digitazione',

                'guestPhone_input': 'Numero di cellulare del viaggiatore *',
                'guestPhone_tooltip': 'Avere il tuo numero ci permetterà di contattarti velocemente in caso di necessità',

              }, // viaggiatore

              'beneficiario':{

                'guestFirstName_input': 'Nome *',
                'guestFirstName_tooltip': 'Inserisci il nome',

                'guestLastName_input': 'Cognome *',
                'guestLastName_tooltip': 'Inserisci il cognome',

                'guestEmail_input': 'Email *',
                'guestEmail_tooltip': 'Il voucher Salabam con il codice e le istruzioni su come prenotare sarà inviato a questo indirizzo',
                'guestEmailConf_input': 'Conferma email *',
                'guestEmailConf_tooltip': 'Te la facciamo riscrivere per scoprire eventuali errori di digitazione',

                'guestPhone_input': 'Numero di cellulare *',
                'guestPhone_tooltip': 'Avere il tuo numero ci permetterà di contattarti velocemente in caso di necessità',

              }, // beneficiario

            }, // strings
        }
    },
    computed:
    {
    },
    methods:
    {
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateBlock()
        },
        validateBlock()
        {
            let valid = this.mixinValidateFields([
                {
                    ref: this.$refs['guestFirstName'],
                    type: 'firstName',
                    value: this.guestFirstName,
                },
                {
                    ref: this.$refs['guestLastName'],
                    type: 'lastName',
                    value: this.guestLastName,
                },
                {
                    ref: this.$refs['guestEmail'],
                    type: 'email',
                    value: this.guestEmail,
                },
                {
                    ref: this.$refs['guestEmailConf'],
                    type: 'email',
                    value: this.guestEmailConf,
                },
                {
                    ref: this.$refs['guestEmailConf'],
                    type: 'emailConfirm',
                    value: (this.guestEmailConf == this.guestEmail),
                },
                {
                    ref: this.$refs['guestPhone'],
                    type: 'phone',
                    value: this.guestPhone,
                },
            ])

            if(process.env.VUE_APP_MODE != 'production') window.console.log('emit event checkout-pax sidebarBlockChanged')

            this.$emit('sidebarBlockChanged',{
                name: 'checkoutPax',
                status: valid,
                fields: {
                    'guestFirstName': this.guestFirstName,
                    'guestLastName': this.guestLastName,
                    'guestEmail': this.guestEmail,
                    'guestEmailConf': this.guestEmailConf,
                    'guestPhone': this.guestPhone,
                }
            })

            return valid
        },
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .checkoutPax
    {
    }
</style>