var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkoutPax" }, [
    _c("div", { staticClass: "sidebarRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestFirstName",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: _vm.strings[_vm.subject].guestFirstName_input,
              "data-e2e": "guestFirstName",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestFirstName,
              callback: function ($$v) {
                _vm.guestFirstName = $$v
              },
              expression: "guestFirstName",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestFirstName")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: _vm.strings[_vm.subject].guestFirstName_tooltip,
                expression: "strings[subject].guestFirstName_tooltip",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestLastName",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: _vm.strings[_vm.subject].guestLastName_input,
              "data-e2e": "guestLastName",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestLastName,
              callback: function ($$v) {
                _vm.guestLastName = $$v
              },
              expression: "guestLastName",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestLastName")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: _vm.strings[_vm.subject].guestLastName_tooltip,
                expression: "strings[subject].guestLastName_tooltip",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestEmail",
            attrs: {
              size: _vm.inputSize,
              type: "email",
              placeholder: _vm.strings[_vm.subject].guestEmail_input,
              "data-e2e": "guestEmail",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestEmail,
              callback: function ($$v) {
                _vm.guestEmail = $$v
              },
              expression: "guestEmail",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestEmail")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: _vm.strings[_vm.subject].guestEmail_tooltip,
                expression: "strings[subject].guestEmail_tooltip",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestEmailConf",
            attrs: {
              size: _vm.inputSize,
              type: "email",
              placeholder: _vm.strings[_vm.subject].guestEmailConf_input,
              "data-e2e": "guestEmailConf",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestEmailConf,
              callback: function ($$v) {
                _vm.guestEmailConf = $$v
              },
              expression: "guestEmailConf",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestEmailConf")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: _vm.strings[_vm.subject].guestEmailConf_tooltip,
                expression: "strings[subject].guestEmailConf_tooltip",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestPhone",
            attrs: {
              size: _vm.inputSize,
              type: "tel",
              placeholder: _vm.strings[_vm.subject].guestPhone_input,
              "data-e2e": "guestPhone",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestPhone,
              callback: function ($$v) {
                _vm.guestPhone = $$v
              },
              expression: "guestPhone",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestPhone")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: _vm.strings[_vm.subject].guestPhone_tooltip,
                expression: "strings[subject].guestPhone_tooltip",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }