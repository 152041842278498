<template>
    <div class="checkoutGiftCard">
        <div class="sidebarRow giftCardRowTitle">
           <h5 class="sidebarStepTitle">
                <b-form-checkbox v-model="isGift" @change="toggleGift">
                    <span class="labelGiftCard ml-1">&Egrave; un regalo?</span>
                </b-form-checkbox>
            </h5>
        </div>
        <div>
            <h5 class="sidebarAccordionTitle giftCardModalLink small">
                Puoi inviare il voucher al destinatario via email oppure puoi scegliere la scatola con dentro una gift card da collezione.
                <strong v-b-modal.modalGiftCardsInfo>Vedi la gift card!</strong>
            </h5>
        </div>
        <div v-if="isGift">

            <ModalGiftCard :id="'modalGiftCard_'+_uid" />

            <div class="panel panel-white">
                <span class="small">Per acquisti multipli con più di un destinatario scrivici a: <a href="mailto:sales@salabam.com">sales@salabam.com</a></span>
            </div>
            <br>

            <!--campi obbligatori gift card-->
            <div class="sidebarRow giftCardRow">
                <div class="customInputBlock">
                    <b-form-input :size="inputSize" @input="validateBlock()" v-model="giftCardFirstName" ref="giftCardFirstName" type="text" placeholder="Nome destinatario *" />
                    <span class="formError"></span>
                    <span class="formClear" @click="clearField('giftCardFirstName')"></span>
                    <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Nome del destinatario del regalo'" />
                </div>
            </div>
            <div class="sidebarRow giftCardRow">
                <div class="customInputBlock">
                    <b-form-input :size="inputSize" @input="validateBlock()" v-model="giftCardLastName" ref="giftCardLastName" type="text" placeholder="Cognome destinatario *" />
                    <span class="formError"></span>
                    <span class="formClear" @click="clearField('giftCardLastName')"></span>
                    <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Cognome del destinatario del regalo'" />
                </div>
            </div>

            <h5 :class="'sidebarAccordionTitle '+(giftCardEmailVisible ? 'activeStep' : '')" v-b-toggle.giftCardEmail>
                <span class="labelGiftCard">Lo voglio regalare via email</span> <SvgChevronDown />
            </h5>
            <b-collapse id="giftCardEmail" v-model="giftCardEmailVisible" accordion="giftCardStatus">
                <div class="sidebarRow giftCardRow mb-3">
                    <h5 class="sidebarStepTitle text-left">
                        <b-form-checkbox v-model="useBillingData" @change="toggleShippingData">
                            <p class="small mb-0">
                               Vuoi consegnare il voucher di persona? Usa lo stesso indirizzo email usato sopra.
                               <br>
                               <strong class="underline" v-b-modal.modalGiftCardsInfo>Vedi la gift card!</strong>
                            </p>
                        </b-form-checkbox>
                    </h5>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardEmail" ref="giftCardEmail" type="email" placeholder="Email *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardEmail')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Scrivi qui l\'email del destinatario'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardEmailConfirm" ref="giftCardEmailConfirm" type="email" placeholder="Conferma email *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardEmailConfirm')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Conferma l\'email del destinatario'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-textarea :size="inputSize" @input="validateBlock()" v-model="giftCardMessage" ref="giftCardMessage" placeholder="Messaggio per il destinatario" no-resize/>
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardMessage')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Scrivi qui il messaggio per il destinatario'" />
                    </div>
                </div>
            </b-collapse>
            <h5 :class="'sidebarAccordionTitle '+(giftCardBoxVisible ? 'activeStep' : '')" v-b-toggle.giftCardBox>
                <span class="labelGiftCard">Voglio regalare scatola e gift card</span> <SvgChevronDown />
            </h5>
            <b-collapse id="giftCardBox" v-model="giftCardBoxVisible" accordion="giftCardStatus">
                
                <!--
                <p class="small secondary pointer text-center cursor-pointer" @click="mixinGoTo('gift-cards',{})">
                    <strong>Guarda la scatolina e la gift card</strong>
                </p>
                -->
                
                <div class="sidebarRow giftCardRow mb-3">
                    <h5 class="sidebarStepTitle text-left">
                        <b-form-checkbox v-model="useBillingData" @change="toggleShippingData">
                            <p class="small mb-0">
                                Vuoi consegnare di persona il regalo? Usa lo stesso indirizzo che hai inserito sopra.
                                <br>
                                <strong class="underline" v-b-modal.modalGiftCardsInfo>Vedi la gift card!</strong>
                            </p>
                        </b-form-checkbox>
                    </h5>
                </div>

                <p class="text-center"><small>Indirizzo di consegna</small></p>
                                
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardShippingFirstName" ref="giftCardShippingFirstName" type="text" placeholder="Nome destinatario *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardShippingFirstName')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Nome del destinatario della spedizione'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardShippingLastName" ref="giftCardShippingLastName" type="text" placeholder="Cognome destinatario *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardShippingLastName')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Cognome del destinatario della spedizione'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardAddress" ref="giftCardAddress" type="text" placeholder="Indirizzo *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardAddress')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Indirizzo del destinatario'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" v-model="giftCardCo" ref="giftCardCo" type="text" placeholder="Dettagli indirizzo (azienda, scala, ecc.)" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardCo')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Dettagli indirizzo (azienda, scala, ecc.)'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardPostalCode" ref="giftCardPostalCode" type="text" placeholder="CAP *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardPostalCode')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'CAP del destinatario'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardTown" ref="giftCardTown" type="text" placeholder="Città *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardTown')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Città del destinatario'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardProvince" ref="giftCardProvince" type="text" placeholder="Provincia *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardProvince')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Provincia del destinatario'" />
                    </div>
                </div>
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input disabled :size="inputSize" @input="validateBlock()" v-model="giftCardCountry" ref="giftCardCountry" type="text" placeholder="Paese *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardCountry')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Questo servizio è disponibile solo verso l\' Italia'" />
                    </div>
                </div>
                <div class="customInputBlock">
                    <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardPhone" ref="giftCardPhone" type="text" placeholder="Cellulare del destinatario *" />
                    <span class="formError"></span>
                    <span class="formClear" @click="clearField('giftCardPhone')"></span>
                    <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Cellulare del destinatario, per il corriere'" />
                </div>

                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock mb-2">
                        <b-form-input :size="inputSize" @input="validateBlock()" v-model="giftCardBoxEmail" ref="giftCardBoxEmail" type="email" placeholder="Email del destinatario" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardBoxEmail')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Scrivi qui l\'email del destinatario'" />
                    </div>
                </div>

                <p class="small text-center emailWarning">
                  Se ci fornisci l'email del destinatario gli invieremo una notifica della spedizione (opzionale)
                </p>
                
                <!--
                <div class="sidebarRow giftCardRow">
                    <div class="customInputBlock">
                        <b-form-input :size="inputSize" :disabled="useBillingData" @input="validateBlock()" v-model="giftCardBoxEmailConfirm" ref="giftCardBoxEmailConfirm" type="email" placeholder="Conferma email *" />
                        <span class="formError"></span>
                        <span class="formClear" @click="clearField('giftCardBoxEmailConfirm')"></span>
                        <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Conferma l\'email del destinatario'" />
                    </div>
                </div>
                -->
            </b-collapse>
        </div>
    </div>
</template>

<script>
import ModalGiftCard from './../common/modal-gift-card'
import SvgInfoCircle from './../../svg/info-circle'
import SvgChevronDown from './../../svg/chevron-down'

export default {
    name: 'checkoutGiftCard',
    components:
    {
        ModalGiftCard,
        SvgInfoCircle,
        SvgChevronDown,
    },
    props:
    {
        inputSize: String,
        pax: Object,
        billToPax: Object,
    },
    mounted()
    {
        this.validateBlock()
        this.$root.$on('bv::collapse::state', () => {
            this.validateBlock()
            this.useBillingData = false //per evitare che se si spunta la checkbox e poi si apre il secondo cassetto ci si trovi tutti gli input disabled
        })
    },
    data()
    {
        return {
            isGift: false,
            giftCardEmailVisible: false,
            giftCardBoxVisible: false,
            useBillingData: false,

            // campi obbligatori
            giftCardFirstName: (process.env.VUE_APP_MODE == 'development')? 'Zlatan' : '',
            giftCardLastName: (process.env.VUE_APP_MODE == 'development')? 'Ibrahimović' : '',

            //campi per invio via email
            giftCardEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            giftCardEmailConfirm: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            giftCardMessage: (process.env.VUE_APP_MODE == 'development')? 'Messaggio di prova gift card' : '',

            //campi per invio fisico
            giftCardShippingFirstName: (process.env.VUE_APP_MODE == 'development')? 'Zlatan' : '',
            giftCardShippingLastName: (process.env.VUE_APP_MODE == 'development')? 'Ibrahimović' : '',
            giftCardAddress: (process.env.VUE_APP_MODE == 'development')? 'Via Xxx 12 B' : '',
            giftCardPostalCode: (process.env.VUE_APP_MODE == 'development')? '01122' : '',
            giftCardTown: (process.env.VUE_APP_MODE == 'development')? 'Marina di Montemarciano' : '',
            giftCardProvince: (process.env.VUE_APP_MODE == 'development')? 'AN' : '',
            giftCardCountry: 'Italia',//(process.env.VUE_APP_MODE == 'development')? 'italia' : '',
            giftCardBoxEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            //giftCardBoxEmailConfirm: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            giftCardCo: (process.env.VUE_APP_MODE == 'development')? 'Interno 3 scala 2' : '',
            giftCardPhone: (process.env.VUE_APP_MODE == 'development')? '12341234' : '',

            // salvo provvisoriamente i dati di spedizione per recuperarli se si disattiva la checkbox che recupera i dati di fatturazione
            // vedi metodo toggleShippingData()
            tempShippingData: {
                tempGiftCardShippingFirstName: this.giftCardShippingFirstName,
                tempGiftCardShippingLastName: this.giftCardShippingLastName,
                tempGiftCardPhone: this.giftCardPhone,

                //tempGiftCardBoxEmail: this.giftCardBoxEmail,
                //tempGiftCardBoxEmailConfirm: this.giftCardBoxEmailConfirm,

                tempGiftCardAddress: this.giftCardAddress,
                tempGiftCardPostalCode: this.giftCardPostalCode,
                tempGiftCardTown: this.giftCardTown,
                tempGiftCardProvince: this.giftCardProvince,
                tempCountry: '',
                tempGiftCardEmail: this.giftCardEmail,
                tempGiftCardEmailConfirm: this.giftCardEmailConfirm,
            },
        }
    },
    computed:
    {
    },
    watch:
    {
        // mutua esclusione delle due opzioni
        giftCardEmailVisible: function()
        {
            this.giftCardBoxVisible = !this.giftCardEmailVisible
        },
        giftCardBoxVisible: function()
        {
            this.giftCardEmailVisible = !this.giftCardBoxVisible
        }
    },
    methods:
    {
        toggleGift(value)
        {
            if(value) // è un regalo
            {
                // valido i campi ed emetto il mixin
                this.validateBlock()
            }
            else // non è un regalo
            {
                // non valido i campi ed emetto il mixin con status:false e fields:{}
                this.$emit('sidebarBlockChanged',{
                    name: 'checkoutGiftCard',
                    status: true,
                    fields: {}
                })                
            }
        },
        updatePaxAndBillToPaxFields()
        {

            if(process.env.VUE_APP_MODE != 'production') window.console.log('checkout-gift-card.vue updatePaxAndBillToPaxFields')
            if(process.env.VUE_APP_MODE != 'production') window.console.log(this.pax.fields.guestEmailConf)

            // nextTick da il tempo a useBillingData di essere aggiornato, altrimenti questa chiamata di funzione viene eseguita prima del tempo
            this.$nextTick(() => {
                if(!this.useBillingData) return
                // popolo i campi con quelli che arrivano da pax e billToPax
                this.giftCardShippingFirstName = this.pax.fields.guestFirstName
                this.giftCardShippingLastName = this.pax.fields.guestLastName
                this.giftCardPhone = this.pax.fields.guestPhone

                // lasciare libero indirizzo email per notifica spedizione in basso
                //this.giftCardBoxEmail = this.pax.fields.guestEmail
                //this.giftCardBoxEmailConfirm = this.pax.fields.guestEmailConf

                this.giftCardAddress = this.billToPax.fields.guestAddress
                this.giftCardPostalCode = this.billToPax.fields.guestPostalCode
                this.giftCardTown = this.billToPax.fields.guestCity
                this.giftCardProvince = this.billToPax.fields.guestProvince
                //this.country = ''
                this.giftCardEmail = this.pax.fields.guestEmail
                this.giftCardEmailConfirm = this.pax.fields.guestEmailConf
                this.validateBlock()
            })
        },
        toggleShippingData(value)
        {
            if(value)
            {
                // salvo provvisoriamente per poterli recuperare se si disattiva la checkbox
                this.tempShippingData.tempGiftCardShippingFirstName = this.giftCardShippingFirstName
                this.tempShippingData.tempGiftCardShippingLastName = this.giftCardShippingLastName
                this.tempShippingData.tempGiftCardPhone = this.giftCardPhone

                // lasciare libero indirizzo email per notifica spedizione in basso
                //this.tempShippingData.tempGiftCardBoxEmail = this.giftCardBoxEmail
                //this.tempShippingData.tempGiftCardBoxEmailConfirm = this.giftCardBoxEmailConfirm


                this.tempShippingData.tempGiftCardAddress = this.giftCardAddress
                this.tempShippingData.tempGiftCardPostalCode = this.giftCardPostalCode
                this.tempShippingData.tempGiftCardTown = this.giftCardTown
                this.tempShippingData.tempGiftCardProvince = this.giftCardProvince
                this.tempShippingData.tempCountry = this.country

                this.tempGiftCardEmail = this.giftCardEmail
                this.tempGiftCardEmailConfirm = this.giftCardEmailConfirm

                // aggiorno i campi perché la reattività di Vue qui non arriva
                this.updatePaxAndBillToPaxFields()
            }
            else
            {
                // recupero i campi precedentemente inseriti salvati in tempShippingData
                this.giftCardShippingFirstName = this.tempShippingData.tempGiftCardShippingFirstName
                this.giftCardShippingLastName = this.tempShippingData.tempGiftCardShippingLastName
                this.giftCardPhone = this.tempShippingData.tempGiftCardPhone

                // lasciare libero indirizzo email per notifica spedizione in basso
                //this.giftCardBoxEmail = this.tempShippingData.tempGiftCardBoxEmail
                //this.giftCardBoxEmailConfirm = this.tempShippingData.tempGiftCardBoxEmailConfirm


                this.giftCardAddress = this.tempShippingData.tempGiftCardAddress
                this.giftCardPostalCode = this.tempShippingData.tempGiftCardPostalCode
                this.giftCardTown = this.tempShippingData.tempGiftCardTown
                this.giftCardProvince = this.tempShippingData.tempGiftCardProvince
                this.country = this.tempShippingData.tempCountry

                this.giftCardEmail = this.tempGiftCardEmail
                this.giftCardEmailConfirm = this.tempGiftCardEmailConfirm
            }
            this.validateBlock()
        },
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateBlock()
        },
        validateBlock()
        {
            let fields = {}

            if(!this.isGift){
              // se non è stato checkato "è un regalo" ritono valido
              this.$emit('sidebarBlockChanged',{
                name: 'checkoutGiftCard',
                status: true,
                fields: fields
              })
              return true
            }

            // valido i campi del destinatario, che sono obbligatori a prescindere dall'opzione email/consegna fisica
            let valid = this.mixinValidateFields([
                {
                    ref: this.$refs['giftCardFirstName'],
                    type: 'firstName',
                    value: this.giftCardFirstName,
                },
                {
                    ref: this.$refs['giftCardLastName'],
                    type: 'lastName',
                    value: this.giftCardLastName,
                }
            ])

            if(valid)
            {
                fields.giftCardFirstName = this.giftCardFirstName
                fields.giftCardLastName = this.giftCardLastName
            }
            
            // valido i campi SOLO per l'invio via email
            if(this.giftCardEmailVisible)
            {
                valid = valid && this.mixinValidateFields([
                    {
                        ref: this.$refs['giftCardEmail'],
                        type: 'email',
                        value: this.giftCardEmail,
                    },
                    {
                        ref: this.$refs['giftCardEmailConfirm'],
                        type: 'emailConfirm',
                        value: (this.giftCardEmailConfirm == this.giftCardEmail),
                    },
                    {
                        ref: this.$refs['giftCardMessage'],
                        type: 'textarea',
                        value: this.giftCardMessage,
                        optional: true,
                    }
                ])

                if(valid)
                {
                    fields.mode = 'email'
                    fields.giftCardEmail = this.giftCardEmail
                    fields.giftCardMessage = this.giftCardMessage
                }
            }

            // valido i campi SOLO per l'invio dell box fisico
            if(this.giftCardBoxVisible)
            {
                valid = valid && this.mixinValidateFields([
                    {
                        ref: this.$refs['giftCardShippingFirstName'],
                        type: 'firstName',
                        value: this.giftCardShippingFirstName,
                    },
                    {
                        ref: this.$refs['giftCardShippingLastName'],
                        type: 'lastName',
                        value: this.giftCardShippingLastName,
                    },
                    {
                        ref: this.$refs['giftCardAddress'],
                        type: 'address',
                        value: this.giftCardAddress,
                    },
                    {
                        ref: this.$refs['giftCardPostalCode'],
                        type: 'postalCode',
                        value: this.giftCardPostalCode,
                    },
                    {
                        ref: this.$refs['giftCardTown'],
                        type: 'city',
                        value: this.giftCardTown,
                    },
                    {
                        ref: this.$refs['giftCardCountry'],
                        type: 'country',
                        value: this.giftCardCountry,
                    },
                    {
                        ref: this.$refs['giftCardProvince'],
                        type: 'province',
                        value: this.giftCardProvince,
                    },
                    {
                      ref: this.$refs['giftCardPhone'],
                      type: 'phone',
                      value: this.giftCardPhone,
                    },
                    {
                        ref: this.$refs['giftCardBoxEmail'],
                        type: 'email',
                        value: this.giftCardBoxEmail,
                        optional: true,
                    },
                    /*
                    {
                        ref: this.$refs['giftCardBoxEmailConfirm'],
                        type: 'emailConfirm',
                        value: (this.giftCardBoxEmailConfirm == this.giftCardBoxEmail),
                    },
                    */
                ])

                if(valid)
                {
                    fields.mode = 'box'
                    fields.giftCardShippingFirstName = this.giftCardShippingFirstName
                    fields.giftCardShippingLastName = this.giftCardShippingLastName
                    fields.giftCardAddress = this.giftCardAddress
                    fields.giftCardPostalCode = this.giftCardPostalCode
                    fields.giftCardTown = this.giftCardTown
                    fields.giftCardCountry = this.giftCardCountry
                    fields.giftCardProvince = this.giftCardProvince
                    fields.giftCardBoxEmail = this.giftCardBoxEmail
                    fields.giftCardCo = this.giftCardCo
                    fields.giftCardPhone = this.giftCardPhone
                }                
            }
            
            fields = valid ? fields : {}


            // almeno uno dei 2 cassetti deve essere stato aperto e validato
            // (gestisce il caso che appena selezioni il regalo e metti il nome del destinatario
            // entrambi i cassetti sono chiusi, nessuno dei 2 viene validato, e ritornava valid true
            // attivando erroneamente il tasto conferma)
            valid = valid && (this.giftCardEmailVisible || this.giftCardBoxVisible)


            this.$emit('sidebarBlockChanged',{
                name: 'checkoutGiftCard',
                status: valid,
                fields: fields
            })

            return valid
        },
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .checkoutGiftCard,
    #sidebarCheckout .checkoutGiftCard
    {
        .sidebarAccordionTitle[aria-controls]
        {
            min-width:100%;
            display:flex;
            justify-content: space-between;
        }
        .sidebarAccordionTitle,
        .sidebarAccordionTitle[aria-expanded]
        {
            //color:$secondary;
            font-size: 16px;
            cursor:pointer;
            margin:$grid-gutter-width/2 0;
            svg *
            {
                //stroke:$secondary;
            }
        }
        .activeStep
        {
        }
        .giftCardRowTitle
        {
            margin-bottom:0;
            //text-align:center;
            .custom-control-label
            {
                //font-weight:bold;
                padding-top:0;
                margin-bottom:0;
            }
        }
        .custom-control-label
        {
            background:transparent;
        }
        //.custom-control-label,
        .small
        {
            font-size: 13px;
            font-family:$headings-font-family;
        }
        .emailWarning
        {
            margin-bottom: 10px;
            line-height: 120%; 
        }
        .giftCardRow
        {
            margin-bottom:0; // tengo i blocchi interni un po' più compatti per salvare spazio in verticale
        }
        .giftCardModalLink
        {
            line-height:140%;
            strong
            {
                display:block;
                text-decoration: underline;
                font-weight:bold;
                text-decoration:underline;
            }
        }
        .panel-white
        {
            font-size:12px;
            background:rgba($white,0.5);
            padding: 16px 20px;
        }
        .giftCardRowTitle
        {
            .custom-checkbox
            {
                display: flex;
                align-items: center;
                justify-content: center;   
            }
            .labelGiftCard
            {
                font-size: 14px;
                @include media-breakpoint-up(xl)
                {
                    font-size:18px;
                }            
            }
        }
    }
</style>